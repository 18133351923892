import React from 'react';
import Video from "../../videos/background_particules_1.mp4";
import {HeroContainer, HeroBg, VideoBg, HeroContent, HeroP, HeroH1} from "./HeroElements";

const HeroSection = ({desc}) => {
    return (
        <HeroContainer id="home">
            <HeroBg>
                <VideoBg autoPlay loop muted playsInline src={Video} type="video/mp4"/>
            </HeroBg>
            <HeroContent>
                <HeroH1>PRAT THEO</HeroH1>
                <HeroP>{desc}</HeroP>
            </HeroContent>
        </HeroContainer>
    );
};

export default HeroSection;