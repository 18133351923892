import React, {useState} from 'react';
import {Button} from "../ButtonElements";
import Icon1 from '../../images/Projets/website.svg';
import Icon2 from '../../images/Projets/school.svg'
import Icon3 from '../../images/Projets/esport.svg'
import Icon4 from '../../images/Projets/3D.svg'
import Fond1 from '../../images/Projets/esport2.svg'
import Fond2 from '../../images/Projets/jeux3.svg'


import {
    ProjectsContainer,
    ProjectsWrapper,
    ProjectsH1,
    ProjectsCard,
    // ProjectsIcon,
    ProjectsP,
    ProjectsH2,
    ProjectsType,
    // ProjectsVideo,
    ImageCard,
    TextCard,
    BtnWrapper,
    ArrowForward,
    ArrowRight,
    ProjectsIcon,
    ProjectsFond1,
    ProjectsFond2
} from "./ProjectElements";

const Projects = ({   titre,
                      projetType1,
                      projetTitre1,
                      projetDesc1,
                      projetLangUse1,
                      projetType2,
                      projetTitre2,
                      projetDesc2,
                      projetLangUse2,
                      projetType3,
                      projetTitre3,
                      projetDesc3,
                      projetLangUse3,
                      projetType4,
                      projetTitre4,
                      projetDesc4,
                      projetLangUse4,
                      apercuBouton,
                      githubBouton,}) => {

    const [hover1, setHover1] = useState(false);

    const onHover1 = () => {
        setHover1(!hover1);
    };

    const [hover2, setHover2] = useState(false);

    const onHover2 = () => {
        setHover2(!hover2);
    };

    const onClick1 = () => {
        window.open('https://www.figma.com/proto/XQCZmEwoNN0YRNaQLJT1S2/Alpiniste-preview-mockup?page-id=0%3A1&node-id=11%3A755&viewport=-126%2C135%2C0.24&scaling=scale-down&starting-point-node-id=11%3A755', '_tab')
    };

    const onClick2 = () => {
        window.open('https://www.figma.com/proto/4YsyAyQb8rG9uAiM6isgAJ/Chocolat?page-id=0%3A1&node-id=7%3A38&starting-point-node-id=7%3A38', '_tab')
        // window.open('https://www.youtube.com/watch?v=6XZhHHDvdTg', '_tab')
    };

    const onClick3 = () => {
        window.open('https://github.com/PanDDaB/projetTutore/tree/master/projetFin', '_tab')
    };

    const onClick4= () => {
        // window.open('https://photos.app.goo.gl/XQtrWG6jXSdJFtDe9', '_tab')
        window.open('https://www.figma.com/proto/DS3tmIUuuvs5AaYY8wbP0c/Untitled?page-id=0%3A1&node-id=1%3A117&viewport=394%2C363%2C0.19&scaling=scale-down-width&starting-point-node-id=1%3A117', '_tab')
    };

    const onClick5= () => {
        window.open('https://github.com/PanDDaB/Beast_Web_Site', '_tab')
    };

    const onClick6 = () => {
        window.open('https://www.youtube.com/watch?v=oAIyTHCHbDM', '_tab')
    };





    return (
        <ProjectsContainer id="projets">

            <ProjectsFond1 src={Fond1}/>
            <ProjectsFond2 src={Fond2}/>

            <ProjectsH1>{titre}</ProjectsH1>
            <ProjectsWrapper>

                {/*Box numéro 1*/}
                <ProjectsCard>
                    <ImageCard>
                        <ProjectsIcon src={Icon1}/>
                    </ImageCard>
                    <TextCard>
                        <ProjectsType>{projetType1}</ProjectsType>
                        <ProjectsH2>{projetTitre1}</ProjectsH2>
                        <ProjectsP>
                            {projetDesc1}
                        </ProjectsP>
                        <ProjectsP>
                            {projetLangUse1}
                        </ProjectsP>
                        <BtnWrapper>
                            <Button to='home' onMouseEnter={onHover1} onMouseLeave={onHover1} onClick={onClick1}>
                                {apercuBouton} {hover1 ? <ArrowForward/> : <ArrowRight/>}
                            </Button>
                        </BtnWrapper>
                    </TextCard>
                </ProjectsCard>

    {/*Box numéro 2*/}
                <ProjectsCard>
                    <ImageCard>
                        <ProjectsIcon src={Icon2}/>
                    </ImageCard>
                    <TextCard>
                        <ProjectsType>{projetType2}</ProjectsType>
                        <ProjectsH2>{projetTitre2}</ProjectsH2>
                        <ProjectsP>
                            {projetDesc2}
                        </ProjectsP>
                        <ProjectsP>
                            {projetLangUse2}
                        </ProjectsP>
                        <BtnWrapper>
                            <Button to='' onMouseEnter={onHover1} onMouseLeave={onHover1} onClick={onClick2}>
                                {apercuBouton} {hover1 ? <ArrowForward/> : <ArrowRight/>}
                            </Button>
                        </BtnWrapper>
                    </TextCard>
                </ProjectsCard>

                {/*Box numéro 3*/}
                <ProjectsCard>
                    <ImageCard>
                        <ProjectsIcon src={Icon3}/>
                    </ImageCard>
                    <TextCard>
                        <ProjectsType>{projetType3}</ProjectsType>
                        <ProjectsH2>{projetTitre3}</ProjectsH2>
                        <ProjectsP>
                            {projetDesc3}
                        </ProjectsP>
                        <ProjectsP>
                            {projetLangUse3}
                        </ProjectsP>
                        <BtnWrapper>
                            <Button to='page' onMouseEnter={onHover1} onMouseLeave={onHover1} onClick={onClick4}>
                                {apercuBouton} {hover1 ? <ArrowForward/> : <ArrowRight/>}
                            </Button>
                        </BtnWrapper>
                    </TextCard>
                </ProjectsCard>

                {/*Box numéro 4*/}
                <ProjectsCard>
                    <ImageCard>
                        <ProjectsIcon src={Icon4}/>
                    </ImageCard>
                    <TextCard>
                        <ProjectsType>{projetType4}</ProjectsType>
                        <ProjectsH2>{projetTitre4}</ProjectsH2>
                        <ProjectsP>
                            {projetDesc4}
                        </ProjectsP>
                        <ProjectsP>
                            {projetLangUse4}
                        </ProjectsP>
                        <BtnWrapper>
                            <Button to='page' onMouseEnter={onHover1} onMouseLeave={onHover1} onClick={onClick6}>
                                Aperçu {hover1 ? <ArrowForward/> : <ArrowRight/>}
                            </Button>
                            {/*<Button to='github' onMouseEnter={onHover2} onMouseLeave={onHover2} onClick={onClick4}>*/}
                            {/*    Github {hover2 ? <ArrowForward/> : <ArrowRight/>}*/}
                            {/*</Button>*/}
                        </BtnWrapper>
                    </TextCard>
                </ProjectsCard>

            </ProjectsWrapper>
        </ProjectsContainer>
    );
};

export default Projects;