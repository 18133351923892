import React from 'react';
import Icon1 from '../../images/Contact/phone.svg'
import Icon2 from '../../images/Contact/mail.svg'
import Icon3 from '../../images/Contact/linkedin.svg'

import {ContactContainer,ContactWrapper, ContactH1, ContactIcon, ContactH2,  ContactCard, Lien} from "./ContactElements";

const Contact = () => {
    return (
        <ContactContainer id="contact">

            <ContactH1>Contact</ContactH1>
            <ContactWrapper>

                {/*Box numéro 1*/}
                <ContactCard>
                        <ContactIcon src={Icon1}/>
                        <ContactH2>+33 6 43 04 85 47</ContactH2>
                </ContactCard>


                {/*Box numéro 3*/}
                <ContactCard>
                        <ContactIcon src={Icon2}/>
                        <ContactH2>theoprat62[at]gmail.com</ContactH2>
                </ContactCard>

                {/*Box numéro 3*/}
                <Lien href="https://www.linkedin.com/in/théo-prat/">
                    <ContactCard>
                            <ContactIcon src={Icon3}/>
                            <ContactH2>Linkedin </ContactH2>
                    </ContactCard>
                </Lien>


            </ContactWrapper>
        </ContactContainer>
    );
};

export default Contact;