export const projetLangueFrancais = {
    titre:'Projets',
    //Projet1
    projetType1: '# UI/UX Design',
    projetTitre1: "Site vitrine d'une alpiniste",
    projetDesc1: "Réalisation de la maquette du site web de Christine Vogondy, alpiniste suisse. L'objectif a été de mettre en avant son projet qui est d'escalader les 14 sommets de plus de 8 000 mètres de hauteur.",
    projetLangUse1:"Logiciel utilisé : Figma, Illustrator",


   // Projet2
    projetType2: '# UI/UX Design',
    projetTitre2: "Création d'une maquette pour site web",
    projetDesc2: "Réalisation d'une maquette d'un site web pour une société nommée Coatle qui vend principalement du chocolat de luxe.",
    projetLangUse2:"Logiciels utilisés : Figma, Illustrator",

    // Projet3
    projetType3: '# UI/UX Design',
    projetTitre3: "Création d'une maquette de site web d'un freelanceur",
    projetDesc3: "Réalisation en équipe d'un site web vitrine pour un freelanceur débutant. Mon rôle est de créer la maquette du site en réponse aux demandes du client. Le projet est toujours en cours..",
    projetLangUse3:"Logiciels utilisés : Figma, Illustrator",


    // Projet4
    projetType4: '# Modélisation 3D',
    projetTitre4: "Création d'un Donut en 3 dimensions",
    projetDesc4: "Réalisation en 3D d'un Donut pour comprendre tous les aspects et les limites de la modélisation 3D avec le logiciel Blender.",
    projetLangUse4:"Logiciel utilisé : Blender",



    apercuBouton:'Aperçu',
    githubBouton:'GitHub'
};

export const projetLangueAnglais = {
    titre:'Projects',
    //Projet1
    projetType1: '# Web',
    projetTitre1: "Creation of a portfolio",
    projetDesc1: "Update of the old portfolio made \"From scratch\" with only HTML and CSS only.\n" +
        "The goal is to show my abilities and skills with this new portfolio\n",
    projetLangUse1:"Programming languages used : React.js, HTML, CSS, javascript.",


    // Projet2
    projetType2: '# Web',
    projetTitre2: "Renovated a part of the 'IUT de Lens' website",
    projetDesc2: "Production in group of the Front-end of the 'IUT de Lens' website.",
    projetLangUse2:"Programming languages used : HTML, CSS, Javascript",

    // Projet3
    projetType3: '# Web',
    projetTitre3: "Creation of a website for an esport team ",
    projetDesc3: "Realization of a showcase website which aim to highlight the esport team.\n" +
        "(Project still in progress)",
    projetLangUse3:"Programming languages used : Vue.js, HTML, CSS, Javascript",


    // Projet4
    projetType4: '# Modélisation 3D',
    projetTitre4: "Creation of a donut in 3 dimension",
    projetDesc4: "Realization of a 3D donut to understand all the aspects and the limits of 3D modeling with Blender.",
    projetLangUse4:"Sofware used : Blender",



    apercuBouton:'Overview',
    githubBouton:'GitHub'
};