import React from 'react';
import {InfoContainer, InfoWrapper, InfoRow, Column1, Column2, TextWrapper, TopLine, Heading, Subtitle, ImgWrap, Img1, Img2} from "./InfoElements";

const InfoSection = ({lightBg, id, imgStart, topLine, lightText, headLine, darkText, description, img1, alt1, img2, alt2}) => {
    return (
        <>
           <InfoContainer lightBg={lightBg} id={id}>
               <Heading lightText={lightText}>{headLine}</Heading>
               <InfoWrapper>
                    <InfoRow imgStart={imgStart}>
                        <Column1>
                            <TextWrapper>
                                <TopLine>{topLine}</TopLine>
                                <Subtitle darkText={darkText}>{description}</Subtitle>
                            </TextWrapper>
                            <ImgWrap>
                                <Img1 src={img1} alt={alt1}/>
                            </ImgWrap>
                        </Column1>
                        <Column2>
                            <ImgWrap>
                                <Img2 src={img2} alt={alt2}/>
                            </ImgWrap>
                        </Column2>
                    </InfoRow>
                </InfoWrapper>
           </InfoContainer>
        </>
    );
};

export default InfoSection;