import React, {useState,} from 'react';
import Sidebar from "../components/SideBar";
import Navbar from "../components/Navbar";
import {navLangueFrancais, navLangueAnglais} from "../components/Navbar/Data";
import HeroSection from "../components/HeroSection";
import {heroLangueFrancais, heroLangueAnglais} from "../components/HeroSection/Data";
import InfoSection from "../components/InfoSection";
import {homeObjOneFrancais, homeObjTwoFrancais, homeObjOneAnglais, homeObjTwoAnglais} from "../components/InfoSection/Data";
import ProjectsSection from "../components/ProjectsSection";
import {projetLangueFrancais, projetLangueAnglais} from "../components/ProjectsSection/Data";
import ContactSection from "../components/ContactSection";

const Home = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    //ecrire une fonction pour passer de langue Francaise à Anglaise

    return (
        <>
          <Sidebar isOpen = {isOpen} toggle={toggle}/>
          <Navbar toggle={toggle} {...navLangueFrancais}/>
            <HeroSection {...heroLangueFrancais}/>
            <InfoSection {...homeObjOneFrancais}/>
            <InfoSection {...homeObjTwoFrancais}/>
            <ProjectsSection {...projetLangueFrancais}/>
            <ContactSection/>
        </>
    );
};

export default Home;