import React, {useEffect, useState} from 'react';
import {Nav, NavbarContainer, MobileIcon, NavMenu, NavItem, NavLinks/*, LangMenu, LangItem, Langlinks*/} from "./NavbarElements";
import {FaBars} from 'react-icons/fa'
import {animateScroll as scroll} from 'react-scroll'
const Navbar = ({toggle, id, langue, home, formations, competences, projets, contact}) => {

    // changement de langue
    // const [isFrench] = useState(false)
    //
    // const changeLangue = () => {
    //     if(/*bouton appuyé*/){
    //         isFrench(true)
    //     } else {
    //         isFrench(false)
    //     }
    // }
    //
    // isFrench(() => {
    //     // changer de langue le site
    // }, [])

    //fin changement de langue

    const [scrollNav, setScrollNav] = useState(false)

    const changeNav = () => {
        if(window.scrollY >= 80){
            setScrollNav(true)
        } else{
            setScrollNav(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, [])

    const toggleHome= () => {
        scroll.scrollToTop()
    }


    return (
        <>
            <Nav scrollNav={scrollNav} id={id}>
                <NavbarContainer>
                    <MobileIcon onClick={toggle}>
                        <FaBars/>
                    </MobileIcon>
                    <NavMenu>

                        <NavItem>
                            <NavLinks to={'home'} onClick={toggleHome}>{home}</NavLinks>
                        </NavItem>

                        <NavItem>
                            <NavLinks to={'formations'}
                                      smooth={true} duration={500} spy={true} exact='true' offset={-80}
                            >{formations}</NavLinks>
                        </NavItem>

                        <NavItem>
                            <NavLinks to={'competences'}
                                      smooth={true} duration={500} spy={true} exact='true' offset={-80}
                            >{competences}</NavLinks>
                        </NavItem>

                        <NavItem>
                            <NavLinks to={'projets'}
                                      smooth={true} duration={500} spy={true} exact='true' offset={-80}
                            >{projets}</NavLinks>
                        </NavItem>

                        <NavItem>
                            <NavLinks to={'contact'}
                                      smooth={true} duration={500} spy={true} exact='true' offset={-80}
                            >{contact}</NavLinks>
                        </NavItem>
                    </NavMenu>
                </NavbarContainer>

                {/*<LangMenu>*/}
                {/*    <LangItem>*/}
                {/*        <Langlinks>{langue}</Langlinks>*/}
                {/*    </LangItem>*/}
                {/*</LangMenu>*/}

            </Nav>
        </>
    );
};

export default Navbar;