import React from 'react';
import './App.css';
import {BrowserRouter as Router} from 'react-router-dom'
import Home from "./pages/index";
import Icon1 from './images/top_fleche.png'
import {animateScroll as scroll} from "react-scroll";
function App() {

    const toggleHome= () => {
        scroll.scrollToTop()
    }

  return (
    <Router>
        <Home/>
        <div id="scroll_to_top">
            <img src={Icon1} alt="Fleche pour aller haut de page" onClick={toggleHome}/>
        </div>
    </Router>
  );
}

export default App;
