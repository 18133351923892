import styled from 'styled-components'
import {Link as LinkScroll} from 'react-scroll'

export const Nav = styled.nav`
  background: ${({ scrollNav}) => (scrollNav ? '#000000' : 'transparent')};
  //background: black;
  height: 80px;
  margin-top: -80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 100;
  //border: solid 1px white;


  @media screen and (max-width: 960px){
    transition: 0.8s all ease;
  }

  @media screen and (min-width:1500px ){
    height: 100px;
    margin-top: -100px;
  }
`

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 1100px;
`

export const MobileIcon = styled.div`
  display: none;
  
  @media screen and (max-width: 768px){
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: white;
  }
`
// Menu avec les liens
export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-right: -22px;
  
  @media screen and (max-width: 768px){
    display: none;
  }
`

export const NavItem = styled.li`
  height: 80px;
  font-size: 1rem;
  
  
  @media screen and (min-width:1500px ){
    font-size: 1.5rem;
  }
  
`

export const NavLinks = styled(LinkScroll)`
  color: white;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  
  &.active{
    border-bottom: 3px solid #CE1BFA;
    //text-decoration: underline;
    //text-decoration-color: white;
  }
`

// Menu langues

export const LangMenu = styled.ul`
  display: flex;
  list-style: none;
  text-align: right;
  //border: solid 1px white;
  //margin-right: 22px;
  
  @media screen and (max-width: 768px){
    display: none;
  }
`

export const LangItem = styled.li`
  height: 80px;
  font-size: 1rem;
  //border: solid 1px white;




  @media screen and (min-width:1500px ){
    font-size: 1.5rem;
  }
`

export const Langlinks = styled(LinkScroll)`
  color: white;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  //margin-left: 50px;
  //border: solid 1px white;


  &.active{
    border-bottom: 3px solid #CE1BFA;
    //text-decoration: underline;
    //text-decoration-color: white;
  }
`