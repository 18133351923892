import styled from 'styled-components'
import {MdArrowForward, MdKeyboardArrowRight} from "react-icons/md";

export const ProjectsContainer = styled.div`
  height: 2100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f1f1f1;
  //position: relative;
  
  
  
  @media screen and (max-width: 1024px){
    height: 3250px;
  }

  @media screen and (max-width: 480px){
    height: 3250px;
  }

  //@media screen and (min-width:1500px ){
  //  transform: scale();
  //}
`

export const ProjectsWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  //grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 16px;
  padding: 0 50px;
  z-index: 10;

  @media screen and (max-width: 480px){
    padding: 0 10px;
    margin: 0;
  }
  
`

export const ProjectsFond1 = styled.img`
  position: absolute;
  width: 250px;
  top: 3150px;
  right: 38px;
  z-index: 0;
  overflow: hidden;
  
  @media screen and (max-width: 1024px){
  visibility: hidden;
  }

  @media screen and (min-width:1500px ){
    top: 3750px;
    transform: scale(1.3);

  }
`

export const ProjectsFond2 = styled.img`
  position: absolute;
  width: 475px;
  top: 4150px;
  left: -200px;
  z-index: 0;
  //overflow: hidden;

  @media screen and (max-width: 1024px){
    visibility: hidden;
  }

  @media screen and (min-width:1500px ){
    top: 4750px;
    transform: scale(1.3);
  }
`


export const ProjectsCard = styled.div`
  background-color: white;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 30px;
  max-height: 400px;
  padding: 50px;
  margin: 20px 0;
  box-shadow: 5px 10px 18px rgba(0,0,0,0.2);
  //transition: all 0.2s ease-in-out;
  //align-items: center;
  
  @media screen and (max-width: 1024px){
    display: block;
    max-height: 800px;
    text-align: center;
    padding: 20px 20px;
    //justify-content: center;
  }

  @media screen and (max-width: 480px){
    max-height: 1600px;
  }
  
  
`


export const ImageCard = styled.div`
  width: 300px;
  height: 300px;
  border: black 1px;
  padding: 0 25px;
  //margin: 20px;
  border-radius: 10px;
  background-color: #f1f1f1;
  //justify-content: center;
  
  @media screen and (max-width: 1024px){
    text-align: center;
    padding: 0 10px;
    //margin: 0 50px;
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (max-width: 480px){
    width: 150px;
    height: 150px;
  }


`


export const TextCard = styled.div`
  //border: solid black 1px;
  margin: 0 30px;
  
  //@media screen and (max-width: 768px){
  //  margin-left: ;
  //}
  
`
export const ProjectsIcon = styled.img`
  width: 200px;
  align-items: center;
  padding: 100px 20px;

  @media screen and (max-width: 480px){
    width: 125px;
    padding: 10px 0;
  }
`

export const ProjectsVideo = styled.video`
  width: 200px;
  align-items: center;
  padding: 20px;
  //margin-bottom: 50px;
  //padding-top: 0;
  //align-items: center;
  
`

export const ProjectsH1 = styled.h1`
  font-size: 2.5rem;
  color: black;
  margin-bottom: 64px;
  padding-top: 100px;

  @media screen and (max-width: 480px){
    font-size: 2rem;
    //padding-top: 0;
  }
  
`

export const ProjectsType = styled.div`
  font-size: 1.2rem;
  color: #CE1BFA;
  padding: 10px 0;
`

export const ProjectsH2 = styled.h2`
  font-size: 1.8rem;
  padding: 10px 0;
  
`

export const ProjectsP = styled.p`
  font-size: 1rem;
  text-align: justify;
  padding: 10px 0;
  
  @media screen and (max-width: 1000px){
    text-align: center;
  }

`

export const BtnWrapper = styled.div`
  //margin-top: 32px;
  padding: 10px 0;
  display: inline-flex;
  align-items: flex-start;
  //display: flex;
  //flex-direction: column;
  //align-items: center;
  //border: solid black 1px;

  @media screen and (max-width: 480px){
    display: block;
  }
`

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
  
`

export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
  
`


