import styled from 'styled-components'


export const InfoContainer = styled.div`
  color: white;
  background: ${({lightBg}) => (lightBg ? '#f1f1f1' : '#131313')};  
  
`

export const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  height: 700px;
  width: 100%;
  max-width: 1500px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;

  @media screen and (max-width: 768px){
    height: 1200px;
  }
  
  @media screen and (max-width: 480px){
    height: 900px;
  }

  @media screen and (min-width:1500px ){
    height: 900px;
    transform: scale(1.2);
  }
`

export const InfoRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto,1fr);
  align-items: center;
  grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'` )};
  //border: solid  white 1px;


  @media screen and (max-width: 768px){
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col1 col2'`: `'col1 col1' 'col2 col2'` )};
  }
  
`

export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
  //border: solid  white 1px;

`

export const Column2 = styled.div`
  margin-bottom: 15px;
  //margin-top: 200px;
  padding: 0 50px;
  grid-area: col2;
  //border: solid  white 1px;

  @media screen and (max-width: 480px){
    padding: 0;
  }
`

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  //padding-bottom: 60px;
`

export const TopLine = styled.p`
  color: #01bf71;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
`

export const Heading = styled.h1`
  text-align: center;
  margin-bottom: 24px;
  padding-top: 100px;
  //padding-left: 50px;
  //margin-top: 50px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? '#f7f8fa' : '#010606')} ;
  //border: solid  white 1px;


  @media screen and (max-width: 480px){
    font-size: 32px;
  }
`

export const Subtitle = styled.p`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: ${({ darkText }) => (darkText ? '#010606' : '#fff')} ;
  
`

export const ImgWrap = styled.div`
  max-width: 600px;
  height: 100%;
`

export const Img1 = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 20px;
`

export const Img2 = styled.img`
  width: 50%;
  margin: 0 0 10px 0;
  padding-left: 0;
  
  @media screen and (max-width: 480px){
    transform: scale(1.1);
  }
`