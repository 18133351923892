import React from 'react';
import {SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarLink, SidebarMenu} from "./SidebarElements";

const Sidebar = ({isOpen, toggle}) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon/>
            </Icon>
                <SidebarWrapper>
                    <SidebarMenu>

                        <SidebarLink to= "home" onClick={toggle}>
                            Home
                        </SidebarLink>

                        <SidebarLink to= "formations" onClick={toggle}>
                            Formations
                        </SidebarLink>

                        <SidebarLink to= "competences" onClick={toggle}>
                            Compétences
                        </SidebarLink>

                        <SidebarLink to= "projets" onClick={toggle}>
                            Projets
                        </SidebarLink>

                        <SidebarLink to= "contact" onClick={toggle}>
                            Contact
                        </SidebarLink>

                    </SidebarMenu>
                </SidebarWrapper>

        </SidebarContainer>
    );
};

export default Sidebar;