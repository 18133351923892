import styled from "styled-components";

export const ContactContainer = styled.div`
  height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #131313;
  //position: relative;
  //border: solid white 1px;

  @media screen and (max-width: 1000px) {
    height: 1000px;
  }

  @media screen and (max-width: 768px) {
    height: 1250px;
  }

  @media screen and (min-width: 1800px) {
    height: 1000px;
  }
`

export const ContactWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 16px ;
  //padding: 0 50px;
  
  @media screen and (max-width: 1000px){
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px){
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`

export const ContactH1 = styled.h1`
  font-size: 2.5rem;
  color: white;
  margin-bottom: 64px;
  padding-top: 25px;
  

  @media screen and (max-width: 480px){
    font-size: 2rem;
    //padding-top: 0;
  }
  
`

export const ContactCard = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  max-height: 340px;
  padding: 50px;
  //margin: 20px 50px;
  box-shadow: 5px 10px 18px rgba(0,0,0,0.2);
  //transition: all 0.2s ease-in-out;
  //align-items: center;
  width: 300px;
  height: 300px;
  
  &:hover{
    transform: scale(1.05);
    transition: all 0.4s ease-in-out;
    cursor: pointer;
    
  }
`


export const ContactIcon = styled.img`
  height: 125px;
  width: 125px;
  margin-bottom: 10px;
  
`



export const ContactH2 = styled.h2`
  font-size: 1rem;
  margin-bottom: 10px;
  padding-top: 30px;
  
`


export const Lien = styled.a`
  text-decoration: none;
  color:black;
`

