export const navLangueFrancais = {
    langue:'English',
    home: 'Accueil',
    formations: 'Formations',
    competences: 'Compétences',
    projets: 'Projets',
    contact: 'Contact'
};

export const navLangueAnglais = {
    langue:'Français',
    home: 'Home',
    formations: 'Formations',
    competences: 'Skills',
    projets: 'Projects',
    contact: 'Contact'
};