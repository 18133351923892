//Partie Français

export const homeObjOneFrancais = {
    id:'formations',
    lightBg: true, //on change la couleur background
    lightText: false, //on change couleur h1
    lightTextDesc: true, //on change couleur description
    headLine:'Formations', //texte h1
    imgStart: false, //Droite ou gauche pour l'image
    img1: require('../../images/Formations/imageAstronaute.svg'),
    alt1:'astronaute', // img + text default
    img2: require('../../images/Formations/image.png'),
    alt2:'phrise chronologique',
    dark: true, // ??
    primary:true, // ??
    darkText: false // couleur du texte

};

export const homeObjTwoFrancais = {
    id:'competences',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    headLine:'Compétences',
    imgStart: false,
    img1: require('../../images/Competences/competences.png'),
    alt1:'image de mes compétences',
    img2: require('../../images/Competences/coding.svg'),
    alt2:'Coding sur pc portable',
    dark: true,
    primary:true,
    darkText: false

};


//Partie Anglaise

export const homeObjOneAnglais = {
    id:'formations',
    lightBg: true, //on change la couleur background
    lightText: false, //on change couleur h1
    lightTextDesc: true, //on change couleur description
    headLine:'Formations', //texte h1
    imgStart: false, //Droite ou gauche pour l'image
    img1: require('../../images/Formations/imageAstronaute.svg').default,
    alt1:'astronaut', // img + text default
    img2: require('../../images/Formations/renard.jpg'),
    alt2:'chronological phrise',
    dark: true, // ??
    primary:true, // ??
    darkText: false // couleur du texte

};

export const homeObjTwoAnglais = {
    id:'competences',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    headLine:'Skills',
    imgStart: false,
    img1: require('../../images/Competences/competences.png'),
    alt1:'picture of my skills',
    img2: require('../../images/Competences/coding.svg').default,
    alt2:'Coding on a laptop',
    dark: true,
    primary:true,
    darkText: false

};
